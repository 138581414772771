import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {
	isBusy$: Observable<boolean>;
	readonly #isBusyChanged$ = new BehaviorSubject<boolean>(false);

	constructor() {
		this.isBusy$ = this.#isBusyChanged$.pipe(debounceTime(100), distinctUntilChanged());
	}

	startBusy(): void {
		this.#isBusyChanged$.next(true);
	}

	stopBusy(): void {
		this.#isBusyChanged$.next(false);
	}
}
